import { FC, useEffect } from 'react';
import {
  embedDashboard,
  EmbeddingOptions,
} from 'amazon-quicksight-embedding-sdk';
import { styled } from '@mui/material/styles';

const Content = styled('div')({
  height: 'fit-content;',
  padding: '8px',
});

export interface QuicksightDashboardProps {
  url?: string;
}

export const QuicksightDashboard: FC<QuicksightDashboardProps> = ({ url }) => {
  useEffect(() => {
    if (!url) {
      return;
    }
    const containerDiv = document.getElementById('dashboardContainer');
    if (!containerDiv) {
      console.warn('Cannot find dashboardContainer');
      return;
    }

    const options: EmbeddingOptions = {
      url: url,
      container: containerDiv,
      scrolling: 'no',
      height: 'AutoFit',
      footerPaddingEnabled: false,
      printEnabled: false,
      undoRedoDisabled: true,
      resetDisabled: true,
      errorCallback: () => {
        console.log('error callback fired');
      },
    };

    console.log('QuicksightDashboard.embedDashboard url: ', url);
    console.log(
      'QuicksightDashboard.embedDashboard executed with options: ',
      options
    );
    embedDashboard(options);
  }, [url]);

  return <Content id="dashboardContainer"></Content>;
};
