import { useEffect, useRef, useState } from 'react';

export const useQuickSightConfigurationChange = (url?: string) => {
  const currentURLRef = useRef('');

  const [isQuickSightDashboardMounted, setIsQuickSightDashboardMounted] =
    useState<boolean>(true);

  useEffect(() => {
    async function handleRemountQuickSightDashboard(
      value?: string
    ): Promise<void> {
      if (!!currentURLRef.current && currentURLRef.current !== value) {
        setIsQuickSightDashboardMounted(false);
        const UPDATING_DELAY = 500;
        await new Promise((resolve) => setTimeout(resolve, UPDATING_DELAY));
        setIsQuickSightDashboardMounted(true);
      }

      if (value) {
        currentURLRef.current = value;
      }
    }

    handleRemountQuickSightDashboard(url).catch();
  }, [url]);

  return isQuickSightDashboardMounted;
};
