import { setPageTitle, useAppContext } from '@bamboard/app-context';
import { useEffect } from 'react';

export function useTitle(title: string) {
  const [, dispatch] = useAppContext();

  useEffect(() => {
    setPageTitle(dispatch, title);
  }, [dispatch, title]);
}
