import { ReactElement } from 'react';
import { DashboardLabel } from '@bamboard/schema';
import { CircularProgress, Box } from '@mui/material';

import { WithLayout, MainLayout } from '../layouts';
import { QuicksightDashboard } from '../components/QuicksightDashboard';
import { useTitle } from '../hooks/useTitle';
import { useQuicksightUrl } from '../hooks/useQuicksightUrl';
import { useQuickSightConfigurationChange } from '../hooks/useQuickSightConfigurationChange';

const LoadingDisplay = () => (
  <Box
    sx={{
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={'5em'} />
  </Box>
);

const ErrorDisplay = () => <p>Failed to fetch embedded dashboard link</p>;

const AbsenceDashboardURLDisplay = () => (
  <p>Missing data. The dashboard URL is not available.</p>
);

const Index: WithLayout = () => {
  useTitle('Overview');

  const { url, isLoading, isError } = useQuicksightUrl(DashboardLabel.Overview);

  const isQuickSightDashboardMounted = useQuickSightConfigurationChange(url);

  if (isLoading) {
    return <LoadingDisplay />;
  }

  if (isError) {
    return <ErrorDisplay />;
  }

  if (!url) {
    return <AbsenceDashboardURLDisplay />;
  }

  return isQuickSightDashboardMounted && <QuicksightDashboard url={url} />;
};

Index.getLayout = (page: ReactElement) => <MainLayout>{page}</MainLayout>;

export default Index;
